<template>
  <div>
    <v-row>
      <v-col cols="auto" align-self="center">
        <v-btn
          icon
          :to="`/veranstaltung/${$route.params.veranstaltung}/anwesenheitsliste`"
          exact
        >
          <v-icon large>mdi-chevron-left</v-icon>
        </v-btn>
      </v-col>
      <v-col>
        <h2 class="white--text text-uppercase">Zuschauerdetails</h2>
      </v-col>
    </v-row>
    <v-row justify="center" class="mt-4" v-if="zuschauer">
      <v-col cols="10" class="px-2 pb-0">
        <v-text-field
          v-model="zuschauer.person.vorname"
          filled
          rounded
          label="Vorname"
          v-on:change="update()"
        ></v-text-field>
      </v-col>
      <v-col cols="10" class="px-2 py-0">
        <v-text-field
          v-model="zuschauer.person.name"
          filled
          rounded
          label="Nachname"
          v-on:change="update()"
        ></v-text-field>
      </v-col>
      <v-col cols="10" class="px-2 py-0">
        <v-text-field
          v-model="zuschauer.person.adresse"
          filled
          rounded
          :readonly="!edit"
          label="Straße + Nr."
          v-on:change="update()"
        ></v-text-field>
      </v-col>
      <v-col cols="4" class="px-2 py-0">
        <v-text-field
          v-model="zuschauer.person.plz"
          filled
          rounded
          label="Postleitzahl"
          v-on:change="update()"
        ></v-text-field>
      </v-col>
      <v-col cols="6" class="px-2 py-0">
        <v-text-field
          v-model="zuschauer.person.ort"
          filled
          rounded
          label="Ort"
          v-on:change="update()"
        ></v-text-field>
      </v-col>
      <v-col
        cols="10"
        class="px-2 py-0"
        v-if="veranstaltung.data.nation_required"
      >
        <v-text-field
          v-model="zuschauer.person.nation"
          filled
          rounded
          label="Nation"
          v-on:change="update()"
        ></v-text-field>
      </v-col>
      <v-col cols="10" class="px-2 py-0">
        <v-text-field
          v-model="zuschauer.person.telefon"
          filled
          rounded
          label="Telefon-/Mobilnummer"
          v-on:change="update()"
        ></v-text-field>
      </v-col>
      <v-col cols="10" class="px-2 py-0">
        <v-text-field
          v-model="zuschauer.zutritt.uhrzeit"
          filled
          rounded
          label="Ankunft"
          v-on:change="update()"
        ></v-text-field>
      </v-col>
      <v-col cols="10" class="px-2 py-0">
        <v-text-field
          v-model="zuschauer.verlassen.uhrzeit"
          filled
          rounded
          label="Verlassen"
          v-on:change="update()"
        ></v-text-field>
      </v-col>
      <v-col cols="10" class="text-center" v-if="zuschauer.weitere_personen">
        <h3 v-if="zuschauer.weitere_personen.length > 0">
          Weitere Personen aus dem Haushalt:
        </h3>
        <v-chip
          v-for="(p, index) in zuschauer.weitere_personen"
          :key="index"
          class="ma-2"
          color="grey lighten-1"
          text-color="#111"
          large
          close
          close-icon="mdi-close"
          @click:close="delete_person(index)"
        >
          <v-avatar left>
            <v-icon>mdi-account-circle</v-icon>
          </v-avatar>
          {{ p.vorname ? p.vorname + ' ' + p.nachname : p }}
        </v-chip>
      </v-col>
      <v-col cols="10" class="text-center">
        <v-btn
          color="success"
          :outlined="add_person"
          @click="add_person = !add_person"
        >
          <v-icon v-if="!add_person">mdi-plus</v-icon>
          {{ add_person ? 'Abbrechen' : 'Weitere Person aus Haushalt' }}
        </v-btn>
      </v-col>
      <v-expand-transition>
        <v-col cols="10" class="text-center" v-if="add_person">
          <v-row>
            <v-col cols="12">
              <v-text-field
                label="Vorname"
                filled
                rounded
                v-model="new_person.vorname"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                label="Nachname"
                filled
                rounded
                v-model="new_person.nachname"
              ></v-text-field>
            </v-col>
            <v-col cols="12" class="pl-0">
              <ggswitches
                :geimpft="false"
                :getestet="false"
                :genesen="false"
                :datum="false"
                @valid="validate_ggg_w_person($event)"
                @change="get_ggg_data_w_person($event)"
              ></ggswitches>
            </v-col>
            <v-col cols="12">
              <v-btn
                color="success"
                :disabled="
                  !new_person.vorname ||
                  !new_person.nachname ||
                  !ggg_valid_w_person
                "
                @click="
                  weitere_person()
                  new_person = { vorname: '', nachname: '' }
                  add_person = false
                "
              >
                <v-icon>mdi-plus</v-icon>
                Hinzufügen
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-expand-transition>
    </v-row>
    <v-divider class="my-5"></v-divider>
    <v-row justify="center">
      <v-col cols="10" class="px-2">
        <v-btn
          :color="template.colors.primary"
          block
          large
          filled
          rounded
          @click="deleteZuschauer()"
        >
          Zuschauer entfernen
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
//import firebase from '../../../../firebaseConfig'
import { mapGetters } from 'vuex'
import store from '../../../../store'
import router from '../../../../routes/index'
import ggswitches from '../../2G-Regel/switches.vue'

export default {
  name: 'zuschauer-bearbeiten',
  data() {
    return {
      zuschauer: '',
      add_person: false,
      new_person: {
        vorname: '',
        nachname: '',
        ggg: '',
      },
      ggg_valid_w_person: '',
    }
  },
  components: {
    ggswitches,
  },
  mounted() {
    if (this.$route.params.zuschauer) {
      firebase
        .firestore()
        .collection('User')
        .doc(this.user.data.uid)
        .collection('Anwesenheit')
        .doc(this.$route.params.zuschauer)
        .get()
        .then((doc) => {
          this.zuschauer = doc.data()
          this.zuschauer.id = doc.id
        })
        .catch((error) => {
          console.log(error)
        })
    } else {
      router.push(
        '/veranstaltung/' +
          this.$route.params.veranstaltung +
          '/anwesenheitsliste',
      )
    }
  },
  computed: {
    ...mapGetters({
      user: 'user',
      veranstaltung: 'veranstaltung',
      template: 'template',
    }),
  },
  methods: {
    validate_ggg_w_person(daten) {
      this.ggg_valid_w_person = daten
    },
    get_ggg_data_w_person(daten) {
      if (daten) {
        this.new_person.ggg = {
          getestet: daten.ggg.getestet || false,
          geimpft: daten.ggg.geimpft || false,
          genesen: daten.ggg.genesen || false,
          geburtsdatum: daten.geburtsdatum || false,
        }
      }
    },
    weitere_person() {
      if (this.new_person) {
        if (!this.zuschauer.weitere_personen) {
          this.zuschauer.weitere_personen = []
        }
        this.zuschauer.weitere_personen.push(this.new_person)
        this.update()
      }
    },
    delete_person(index) {
      var i
      var hilf = this.zuschauer.weitere_personen
      this.zuschauer.weitere_personen = []
      for (i = 0; i < hilf.length; i++) {
        if (hilf[i] != hilf[index]) {
          this.zuschauer.weitere_personen.push(hilf[i])
        }
      }
      this.update()
    },
    update() {
      firebase
        .firestore()
        .collection('User')
        .doc(this.user.data.uid)
        .collection('Anwesenheit')
        .doc(this.$route.params.zuschauer)
        .update({
          person: this.zuschauer.person,
          zutritt: this.zuschauer.zutritt,
          verlassen: this.zuschauer.verlassen,
          weitere_personen: this.zuschauer.weitere_personen,
          ggg: this.ggg,
        })
        .catch((error) => {
          console.log('Es ist ein Fehler aufgetreten!', error)
        })
    },
    deleteZuschauer() {
      firebase
        .firestore()
        .collection('User')
        .doc(this.user.data.uid)
        .collection('Anwesenheit')
        .doc(this.$route.params.zuschauer)
        .delete()
        .then(() => {
          router.push(
            '/veranstaltung/' +
              this.$route.params.veranstaltung +
              '/anwesenheitsliste',
          )
        })
        .catch((error) => {
          console.log(error)
        })
    },
  },
}
</script>
